import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import Loading from 'components/shared-components/Loading';
import KeywordManagement from './keywords/keyword-management';
import AdminRoute from 'components/AdminRoute';

const Home = lazy(() => import('./home'));
const Reports = lazy(() => import('./reports'));
const Products = lazy(() => import('./products'));
const Keywords = lazy(() => import('./keywords'));
const Advertising = lazy(() => import('./advertising'));
const CronLogs = lazy(() => import('./reports/CronLogs'));
const SearchTerm = lazy(() => import('./reports/SearchTerms'));
const AdvertisingLogs = lazy(() => import('./advertising/Logs'));
const InventoryGraph = lazy(() => import('./inventory/MainDash'));
const InventoryDashboard = lazy(() => import('./inventory/InventoryDasboard'));
const InventoryList = lazy(() => import('./inventory/SheetsList'));
const AdvertisingRules = lazy(() => import('./advertising/Rules'));
const Shipments = lazy(() => import('./shipments/ShipmentReports'));
const AdvertisingOptimize = lazy(() => import('./advertising/Optimize'));
const KeywordsByProduct = lazy(() => import('./keywords/KeywordByProduct'));
const AddProduct = lazy(() => import('./products/product-catalog/AddProduct'));
const AmzBulk = lazy(() => import('./products/product-catalog/AmzBulk'));
const EditProduct = lazy(
  () => import('./products/product-catalog/EditProduct')
);
const ViewProducts = lazy(
  () => import('./products/product-catalog/ViewProducts')
);
const ViewParent = lazy(
  () => import('./products/product-catalog/ParentProduct')
);
const KeywordRanking = lazy(() => import('./keywords/KeywordRanking'));
const SalesRanking = lazy(() => import('./sales'));
const SalesRankingV2 = lazy(() => import('./sales-v2'));
const SalesTrendsPage = lazy(() => import('./sales/sales-trends/sales-trends'));
const SalesReport = lazy(() => import('./sales/sales-report'));
const SaleDetailsPage = lazy(() => import('./sales/SaleDetailsPage'));
const Users = lazy(() => import('./users'));
const NotFound = lazy(() => import('./not-found'));
const SkuMappings = lazy(() => import('./sku-mappings'));
const POPage = lazy(() => import('./inventory/PoPage'));
const SalesMapping = lazy(() => import('./sales-v2/SalesMapping'));
const SalesPlanning = lazy(() => import('./planning-and-monitoring'));
const AmzStockHealth = lazy(() => import('./inventory/amz-stock-health'));
const MarketProductResearch = lazy(() => import('./market'));
const MarketProductList = lazy(() => import('./market/ProductList'));

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Routes>
        <Route path="" element={<Home />} />

        <Route path="reports" element={<Reports />} />

        <Route path="reports/cron" element={<CronLogs />} />

        <Route path="reports/searchTerm" element={<SearchTerm />} />

        <Route path="products/*" element={<Products />} />

        <Route path="forecast" element={<InventoryList />} />

        <Route path="po" element={<POPage />} />

        <Route path="inventory" element={<InventoryDashboard />} />

        <Route path="amz-stock-health" element={<AmzStockHealth />} />

        <Route path="sales-planning" element={<SalesPlanning />} />

        <Route path="inventory/sheet/:id" element={<InventoryGraph />} />

        <Route path="product-catalog" element={<ViewProducts />} />

        <Route path="product-catalog/add" element={<AddProduct />} />

        <Route path="product-catalog/amz-bulk" element={<AmzBulk />} />

        <Route path="product-catalog/edit/:id" element={<EditProduct />} />

        <Route path="product-catalog/parent/:id" element={<ViewParent />} />

        <Route path="sku-mappings" element={<SkuMappings />} />
        <Route path="sales-mapping" element={<SalesMapping />} />

        <Route path="keyword-management" element={<KeywordManagement />} />
        <Route path="keywords" element={<Keywords />} />
        <Route path="ranking" element={<KeywordRanking />} />

        <Route path="sales" element={<SalesRanking />} />
        <Route path="sales-v2" element={<SalesRankingV2 />} />
        <Route path="sales-trends" element={<SalesTrendsPage />} />
        <Route path="sales-report" element={<SalesReport />} />
        <Route path="sale-details/:productId" element={<SaleDetailsPage />} />

        <Route path="shipments" element={<Shipments />} />

        <Route path="keywords/product/:id" element={<KeywordsByProduct />} />

        <Route path="advertising" element={<Advertising />} />

        <Route path="advertising/rules" element={<AdvertisingRules />} />

        <Route path="advertising/optimize" element={<AdvertisingOptimize />} />

        <Route path="advertising/logs" element={<AdvertisingLogs />} />

        <Route path="market-research" element={<MarketProductResearch />} />
        <Route path="market-research/:id" element={<MarketProductList />} />

        <Route
          path="users"
          element={
            <AdminRoute>
              <Users />
            </AdminRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default React.memo(AppViews);
